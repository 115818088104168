body {
  margin: 0;

  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none; */
}
* {
  font-family: system-ui;
  -webkit-appearance: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input {
  -webkit-border-radius: 0;
  -webkit-appearance: none;
}
